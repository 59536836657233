.off-canvas {
    &__trigger {
        position: absolute;
        top: 0;
        clip: rect(0, 0, 0, 0);
        // @include sr-only;

        &:checked {
            + .off-canvas__wrapper {
                .off-canvas {
                    &__toggle {
                        &--open {
                            background-color: $off-canvas__toggle-background-color--active;

                            span {
                                &.lines {
                                    @if $main-navigation__mode == off-canvas and not $off-canvas__desktop {
                                        background-color: $off-canvas__toggle-color--active;

                                        @media (min-width: #{$off-canvas__breakpoint}) {
                                            background-color: transparent;
                                        }
                                    } @else {
                                        background-color: $off-canvas__toggle-color--active;
                                    }

                                    &::before,
                                    &::after {
                                        @if $main-navigation__mode == off-canvas and not $off-canvas__desktop {
                                            @media (min-width: #{$off-canvas__breakpoint}) {
                                                top: 0;
                                            }
                                        }
                                        background-color: $off-canvas__toggle-color--active;
                                        transition: top .25s ease, background-color .25s linear, transform .25s linear .25s;
                                    }

                                    &::before {
                                        @if $main-navigation__mode == off-canvas and not $off-canvas__desktop {
                                            @media (min-width: #{$off-canvas__breakpoint}) {
                                                transform: rotate(45deg);
                                            }
                                        }
                                    }

                                    &::after {
                                        @if $main-navigation__mode == off-canvas and not $off-canvas__desktop {
                                            @media (min-width: #{$off-canvas__breakpoint}) {
                                                transform: rotate(-45deg);
                                            }
                                        }
                                    }
                                }

                                &.text {
                                    color: $off-canvas__toggle-color--active;
                                }
                            }
                        }

                        &--off {
                            display: block;
                            position: fixed;
                            @if $main-navigation__mode == off-canvas and $off-canvas__desktop {
                                z-index: ($z-index__off-canvas - 1);
                            } @else {
                                z-index: ($z-index__off-canvas - 1);

                                @media (min-width: #{$off-canvas__breakpoint}) {
                                    z-index: ($z-index__header - 2);
                                }
                            }
                            top: 0;
                            left: 0;
                            width: 100vw;
                            width: calc(var(--vw, 1vw) * 100);
                            height: 100vh;
                            height: calc(var(--vh, 1vh) * 100);
                            background-color: $off-canvas__off;
                        }
                    }

                    &__inner {
                        box-shadow: 0 -1px 24px rgba(0, 0, 0, 0.4);
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }

    &__toggle {
        margin-bottom: 0;

        &--open {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed;
            z-index: ($z-index__off-canvas - 1);
            top: 0;
            @if $off-canvas__align == left {
                left: 0;
            } @else {
                right: 0;
            }
            padding: 0;
            width: $off-canvas__toggle-size--mobile;
            height: $off-canvas__toggle-size--mobile;
            white-space: nowrap;
            background-color: $off-canvas__toggle-background-color;
            cursor: pointer;
            overflow: hidden;
            transition: width .3s ease-in-out, height .3s ease-in-out, background-color .2s linear;

            @include media-breakpoint-up(lg) {
                width: $off-canvas__toggle-size--unpinned;
                height: $off-canvas__toggle-size--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                width: $off-canvas__toggle-size;
                height: $off-canvas__toggle-size;
            }

            html.headroom--unpinned & {
                @include media-breakpoint-up(xxl) {
                    width: $off-canvas__toggle-size--unpinned;
                    height: $off-canvas__toggle-size--unpinned;
                }
            }

            //html.headroom--above-the-fold body.#{$class__template--home} & {
            //	position: absolute;
            //}

            //html.headroom--scrolled.headroom--above-the-fold body.#{$class__template--home} & {
            //	transform: translateY(-100%);
            //}

            //html.headroom--scrolled.headroom--near-the-fold body.#{$class__template--home} &,
            //html.headroom--scrolled.headroom--below-the-fold body.#{$class__template--home} & {
            //	transition: width .3s ease-in-out, height .3s ease-in-out, background-color .25s linear, transform .2s ease-in-out;
            //}

            @include hover-focus-visible {
                background-color: $off-canvas__toggle-background-color--hover;
            }

            span {
                + span {
                    margin-top: 4px;
                }

                &.lines {
                    position: relative;
                    margin-top: calc((28px - 3px) / 2);
                    margin-bottom: calc((28px - 3px) / 2);
                    //position: absolute;
                    //top: 50%;
                    //left: 0;
                    height: 3px;
                    //width: 100%;
                    width: 28px;
                    font-size: 0;
                    line-height: 0;
                    color: transparent;
                    background-color: $off-canvas__toggle-color;
                    //transform: translateY(-50%);
                    transition: background-color 0s linear .25s;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $off-canvas__toggle-color;
                        transition: top .25s ease .25s, background-color .25s linear, transform .25s linear;
                    }

                    &::before {
                        top: -8px;
                    }

                    &::after {
                        top: 8px;
                    }
                }

                &.text {
                    //display: none;
                    font-size: 1.2rem;
                    line-height: 1;
                    color: $off-canvas__toggle-color;
                    transition: color .25s linear;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
        }

        &--off {
            display: none;

            span {
                font-size: 0;
                line-height: 0;
                color: transparent;
            }
        }

        &--close {
            appearance: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;
            top: 0;
            @if $off-canvas__align == left {
                left: 0;
            } @else {
                right: 0;
            }
            width: $header__height--mobile;
            height: $header__height--mobile;
            background-color: $color__primary;
            border: 0;
            border-radius: 0;
            cursor: pointer;
            transition: width .3s ease-in-out, height .3s ease-in-out, background-color .2s linear;

            @include media-breakpoint-up(lg) {
                width: $header__height--unpinned;
                height: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                width: $header__height;
                height: $header__height;
            }

            html.headroom--unpinned & {
                @include media-breakpoint-up(xxl) {
                    width: $header__height--unpinned;
                    height: $header__height--unpinned;
                }
            }

            @include hover-focus-visible {
                background-color: $off-canvas__toggle-background-color--hover;
            }

            span {
                position: relative;
                margin-top: calc((28px - 3px) / 2);
                margin-bottom: calc((28px - 3px) / 2);
                //position: absolute;
                //top: 50%;
                //left: 0;
                height: 3px;
                //width: 100%;
                width: 32px;
                font-size: 0;
                line-height: 0;
                color: $color__white;
                background-color: transparent;
                pointer-events: none;
                //transform: translateY(-50%);
                transition: color .2s linear, background-color 0s linear .25s;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: currentColor;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__search {
        position: absolute;
        z-index: 1;
        top: 0; left: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        //@if $off-canvas__padding-y != 0 {
        //    padding-right: $grid-gutter-width * .5;
        //    padding-left: $grid-gutter-width * .5;
        //}
        //width: calc(100% - #{$off-canvas__toggle-size--mobile});
        padding-right: calc(24px + #{$off-canvas__toggle-size--mobile});
        padding-left: 24px;
        width: 100%;
        height: $off-canvas__toggle-size--mobile;
        transition: padding-right .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out;

        @include media-breakpoint-up(lg) {
            //@if $off-canvas__padding-y != 0 {
            //    padding-right: $off-canvas__padding-y;
            //    padding-left: $off-canvas__padding-y;
            //}
            padding-right: calc(24px + #{$off-canvas__toggle-size--unpinned});
            //width: calc(100% - #{$off-canvas__toggle-size--unpinned});
            height: $off-canvas__toggle-size--unpinned;
        }

        @include media-breakpoint-up(xxl) {
            padding-right: calc(24px + #{$off-canvas__toggle-size});
            //width: calc(100% - #{$off-canvas__toggle-size});
            height: $off-canvas__toggle-size;
        }

        html.headroom--unpinned & {
            @include media-breakpoint-up(xxl) {
                padding-right: calc(24px + #{$off-canvas__toggle-size--unpinned});
                //width: calc(100% - #{$off-canvas__toggle-size--unpinned});
                height: $off-canvas__toggle-size--unpinned;
            }
        }

        .search {
            flex: 1;

            &__preview {
                width: 100%;
            }
        }
    }

    &__inner {
        position: fixed;
        top: 0;
        @if $main-navigation__mode == off-canvas and $off-canvas__desktop {
            z-index: $z-index__off-canvas;
            #{$off-canvas__align}: 0;
            max-width: $off-canvas__max-width;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            transform: if($off-canvas__align == left, translate(-100%, 0), translate(100%, 0));
        } @else {
            z-index: $z-index__off-canvas;
            #{$off-canvas__align}: 0;
            max-width: $off-canvas__max-width;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            transform: if($off-canvas__align == left, translate(-100%, 0), translate(100%, 0));

            @media (min-width: #{$off-canvas__breakpoint}) {
                z-index: ($z-index__header - 1);
                right: 0;
                left: 0;
                max-width: none;
                //height: 780px;
                max-height: 100vh;
                max-height: calc(var(--vh, 1vh) * 100);
                transform: translate(0, -100%);
            }
        }
        display: flex;
        flex-direction: column;
        padding-top: $header__height--mobile;
        //@if $off-canvas__padding-y != 0 {
        //    padding-right: $grid-gutter-width * .5;
        //    padding-left: $grid-gutter-width * .5;
        //}
        padding-right: 24px;
        padding-left: 24px;
        width: 100%;
        background-color: $off-canvas__background;
        //overflow-x: hidden;
        //overflow-y: auto;
        overflow: hidden;
        transition: transform .3s;

        @include media-breakpoint-up(lg) {
            padding-top: $header__height--unpinned;
            //@if $off-canvas__padding-y != 0 {
            //    padding-right: $off-canvas__padding-y;
            //    padding-left: $off-canvas__padding-y;
            //}
        }

        @include media-breakpoint-up(xxl) {
            padding-top: $header__height;
        }

        html.headroom--unpinned & {
            @include media-breakpoint-up(xxl) {
                padding-top: $header__height--unpinned;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 64px;
        background-color: $color__white;

        a {
            &.button {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }
    }

    html.no-js & {
        &__trigger {
            &:focus-visible {
                + .off-canvas__wrapper {
                    .off-canvas {
                        &__toggle {
                            &--open,
                            &--close {
                                background-color: $off-canvas__toggle-background-color--hover;
                            }
                        }
                    }
                }
            }
        }

        &__toggle {
            &--open,
            &--close {
                &:hover {
                    background-color: $off-canvas__toggle-background-color--hover;
                }
            }
        }
    }

    html.js & {
        &__toggle {
            appearance: none;
            border: 0;
            border-radius: 0;
        }
    }
}


/**
 * Desktop off-canvas
 */

 @if $off-canvas__desktop == false {
    @media (min-width: #{$off-canvas__breakpoint}) {
        html.js {
            .off-canvas {
                &__inner {
                    padding-right: 0;
                    padding-left: 0;
                    height: calc(580px + $header__height--mobile);
                    background-color: $color__gray--light;

                    @include media-breakpoint-up(lg) {
                        height: calc(580px + $header__height--unpinned);
                    }

                    @include media-breakpoint-up(xxl) {
                        height: calc(580px + $header__height);
                    }

                    html.headroom--unpinned & {
                        @include media-breakpoint-up(xxl) {
                            height: calc(580px + $header__height--unpinned);
                        }
                    }

                    &.off-canvas--has-footer {
                        height: calc(580px + $header__height--mobile + 64px);

                        @include media-breakpoint-up(lg) {
                            height: calc(580px + $header__height--unpinned + 64px);
                        }

                        @include media-breakpoint-up(xxl) {
                            height: calc(580px + $header__height + 64px);
                        }

                        html.headroom--unpinned & {
                            @include media-breakpoint-up(xxl) {
                                height: calc(580px + $header__height--unpinned + 64px);
                            }
                        }
                    }
                }
            }
        }
    }
 }


/**
 * Desktop menu
 */

@if $main-navigation__mode == menu {
    @media (min-width: #{$off-canvas__breakpoint}) {
        html.js {
            .off-canvas {
                //&__trigger {
                //    display: none !important;
                //}

                &__toggle {
                    &--open,
                    &--close {
                        display: none !important;
                    }
                }

                //&__wrapper {
                //    position: fixed;
                //    z-index: ($z-index__header + 1);
                //    top: 0;
                //    left: 0;
                //    right: 0;
                //}

                //&__inner {
                //    position: static;
                //    z-index: auto;
                //    top: auto;
                //    @if $off-canvas__align == left {
                //        left: auto;
                //    } @else {
                //        right: auto;
                //    }
                //    padding-top: 0;
                //    padding-right: 0;
                //    padding-left: 0;
                //    height: auto;
                //    width: auto;
                //    max-width: none;
                //    background-color: transparent;
                //    overflow: visible;
                //    transform: none;
                //    transition: none;
                //
                //    html.headroom--unpinned & {
                //        padding-top: 0;
                //    }
                //}

                &__search {
                    display: none !important;
                }
            }
        }
    }
}


/**
 * Project styles
 */
