$main-navigation__link--height: 60px;

.main-navigation {
    &__button {
        > .main-navigation__link {
            flex: 1;
            pointer-events: none;
        }
    }
}

html.no-js {
    .main-navigation {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;

        @include moz-scrollbar($thumb-color: $color__gray);
        @include webkit-scrollbar($scrollbar-size: 12px, $thumb-size: 4px, $thumb-color: $color__gray);

        nav {
            ul {
                li {
                    a {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        min-height: $main-navigation__link--height;
                        font-size: 16px;
                        font-weight: $font__weight--bold;
                        // line-height: $main-navigation__link--height - 32px;
                        border-radius: 0;
                        outline: 0;

                        &:not([data-target='home']) {
                            text-decoration: none;
                            color: $color__font;
                            background: {
                                color: transparent;
                                image:linear-gradient(to right, $color__primary 50%, transparent 50%), linear-gradient(to bottom, transparent calc(100% - 1px), $color__gray calc(100% - 1px));
                                position: 100% 100%, 0 0;
                                repeat: no-repeat;
                                size: 200% 3px, 100% 100%;
                            };
                            // border-bottom: 1px solid $color__gray;
                            transition: color .2s linear, background-position .3s ease-in-out;

                            @include hover-focus-visible {
                                // color: $color__primary;
                                background-position: 0 100%, 0 0;
                            }
                        }

                        &[data-target='home'] {
                            padding-top: 32px;
                            // text-decoration: underline;
                            // text-decoration-thickness: .08em;
                            color: $color__primary;
                            border-bottom: 1px solid $color__gray;

                            &:focus-visible {
                                text-decoration: none;
                            }

                            span {
                                &.icon {
                                    flex-shrink: 0;
                                    margin-right: 8px;

                                    img {
                                        filter: $filter__primary;
                                    }
                                }

                                &:not(.icon) {
                                    flex: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        > nav {
            > ul {
                > li {
                    > ul {
                        > li {
                            a {
                                padding-left: 16px;
                            }

                            > ul {
                                > li {
                                    a {
                                        padding-left: 32px;
                                    }

                                    > ul {
                                        > li {
                                            a {
                                                padding-left: 48px;
                                            }

                                            > ul {
                                                > li {
                                                    a {
                                                        padding-left: 64px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

html.js {
    .off-canvas--has-footer {
        .main-navigation {
            max-height: calc(100% - 64px);
        }
    }

    .main-navigation {
        position: relative;
        display: none;
        flex-direction: row;
        flex: 1;
        max-height: 100%;

        &__nav {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: 100%;
            transition: opacity .3s linear, transform .3s linear;

            &.main-navigation__nav--absolute {
                position: absolute;
                top: 0; left: 0;
                width: 100%;

                &.main-navigation__nav--show {
                    opacity: 0;
                    transition: none;

                    &.main-navigation__nav--prev {
                        transform: translateX(-100%);
                    }

                    &.main-navigation__nav--next {
                        transform: translateX(100%);
                    }
                }
            }

            &.main-navigation__nav--hide {
                //opacity: 0;
                pointer-events: none;

                &.main-navigation__nav--prev {
                    transform: translateX(100%);
                }

                &.main-navigation__nav--next {
                    transform: translateX(-100%);
                }
            }
        }

        &__list {
            &.main-navigation__list--header {
                display: flex;
                //flex-direction: column-reverse;
                flex-direction: column;
                flex-shrink: 0;
            }

            &:not(.main-navigation__list--header) {
                overflow-x: hidden;
                overflow-y: auto;

                @include moz-scrollbar($thumb-color: $color__gray);
                @include webkit-scrollbar($scrollbar-size: 12px, $thumb-size: 4px, $thumb-color: $color__gray);
            }
        }

        &__item {
            &.main-navigation__item--active {
                > a,
                > button,
                > button > a {
                    color: $color__primary;
                    // background-size: 100% 3px, 100% 1px;
                    background-position: 0 100%, 0 0;

                    > span {
                        &[aria-hidden] {
                            filter: $filter__primary;
                        }
                    }
                }
            }

            &.main-navigation__item--home {
                > a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: underline;
                    text-decoration-thickness: .08em;
                    color: $color__primary;

                    // @include hover-focus-visible {
                    //     &::before {
                    //         filter: $filter__font;
                    //     }
                    // }

                    &::before {
                        content: "";
                        flex-shrink: 0;
                        display: inline-block;
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                        border: 0;
                        background: {
                            color: transparent;
                            image: url(/bundles/hitcomfrontend/img/material-symbols/400/outlined/home.svg);
                            position: 0 0;
                            repeat: no-repeat;
                            size: 100% 100%;
                        }
                        filter: $filter__primary;
                        transition: filter .15s linear;
                    }
                }
            }

            &.main-navigation__item--header {
                padding-bottom: 24px;
                border-bottom: 1px solid $color__gray;
            }
        }

        &__button {
            appearance: none;
            display: flex;
            flex-direction: row;
            padding: 0;
            font-weight: $font__weight--bold;
            line-height: $main-navigation__link--height;
            text-align: left;
            color: $color__font;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            cursor: pointer;

            // background: {
            //     color: transparent;
            //     image: linear-gradient(to right, $color__primary, $color__primary), linear-gradient(to bottom, $color__gray, $color__gray);
            //     position: 0 100%, 0 100%;
            //     repeat: no-repeat;
            //     size: 100% 0, 100% 1px;
            // };
            // transition: color .2s linear, background-size .3s ease-in-out;

            // @include hover-focus-visible {
            //     background-size: 100% 3px, 100% 1px;
            // }

            background: {
                color: transparent;
                image:linear-gradient(to right, $color__primary 50%, transparent 50%), linear-gradient(to bottom, transparent calc(100% - 1px), $color__gray calc(100% - 1px));
                position: 100% 100%, 0 0;
                repeat: no-repeat;
                size: 200% 3px, 100% 100%;
            };
            // border-bottom: 1px solid $color__gray;
            transition: color .2s linear, background-position .3s ease-in-out;

            @include hover-focus-visible {
                @media (min-width: #{$off-canvas__breakpoint}) {
                    // color: $color__primary;
                    background-position: 0 100%, 0 0;
                }
            }

            &:not(.main-navigation__button--close) {
                width: 100%;
            }

            &.main-navigation__button--prev {
                text-decoration: underline;
                text-decoration-thickness: .08em;
                color: $color__primary;
                background: transparent none;

                > span {
                    &[aria-hidden] {
                        width: 32px;
                        background-image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/chevron_left.svg);
                        background-position: left center;
                    }
                }
            }

            > span {
                &[aria-hidden] {
                    flex-shrink: 0;
                    width: $main-navigation__link--height;
                    height: $main-navigation__link--height;
                    font-size: 0;
                    line-height: 0;
                    color: transparent;
                    text-align: center;

                    background: {
                        color: transparent;
                        image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/east.svg);
                        position: right center;
                        repeat: no-repeat;
                        size: 24px 24px;
                    }
                    filter: $filter__font;
                    transition: filter .15s linear;
                }

                &:not([aria-hidden]) {
                    flex: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        &__link {
            display: flex;
            flex-direction: row;
            padding: 0;
            width: 100%;
            line-height: $main-navigation__link--height;
            text-decoration: none;
            color: $color__font;
            border-radius: 0;

            // background: {
            //     color: transparent;
            //     image: linear-gradient(to right, $color__primary, $color__primary), linear-gradient(to bottom, $color__gray, $color__gray);
            //     position: 0 100%, 0 100%;
            //     repeat: no-repeat;
            //     size: 100% 0, 100% 1px;
            // };
            // transition: color .2s linear, background-size .3s ease-in-out;

            // @include hover-focus-visible {
            //     background-size: 100% 3px, 100% 1px;
            // }

            background: {
                color: transparent;
                image:linear-gradient(to right, $color__primary 50%, transparent 50%), linear-gradient(to bottom, transparent calc(100% - 1px), $color__gray calc(100% - 1px));
                position: 100% 100%, 0 0;
                repeat: no-repeat;
                size: 200% 3px, 100% 100%;
            };
            // border-bottom: 1px solid $color__gray;
            transition: color .2s linear, background-position .3s ease-in-out;

            @include hover-focus-visible {
                @media (min-width: #{$off-canvas__breakpoint}) {
                    // color: $color__primary;
                    background-position: 0 100%, 0 0;
                }
            }

            > span {
                &[aria-hidden] {
                    flex-shrink: 0;
                    width: $main-navigation__link--height;
                    height: $main-navigation__link--height;
                    font-size: 0;
                    line-height: 0;
                    color: transparent;
                    text-align: center;

                    background: {
                        color: transparent;
                        image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/east.svg);
                        position: right center;
                        repeat: no-repeat;
                        size: 24px 24px;
                    }
                    filter: $filter__font;
                    transition: filter .15s linear;
                }

                &:not([aria-hidden]) {
                    flex: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}


/**
 * Desktop off-canvas
 */

//@if $main-navigation__mode == off-canvas and $off-canvas__desktop == false {
@if $off-canvas__desktop == false {
    html.js {
        .off-canvas--has-footer {
            .main-navigation {
                max-height: calc(100% - 64px);
            }
        }

        .main-navigation {
            max-height: 100%;
        }
    }

    @media (min-width: #{$off-canvas__breakpoint}) {
        html.js {
            .main-navigation {
                margin-right: auto;
                margin-left: auto;
                width: 100%;
                max-width: map-get-last-value($container-max-widths);
                height: 580px; // Header + 8 items

                @each $breakpoint, $container-padding-size in $container-padding-sizes {
                    @include media-breakpoint-up(#{$breakpoint}) {
                        padding-right: $container-padding-size;
                        padding-left: $container-padding-size;
                    }
                }

                &__nav {
                    display: flex;
                    flex-direction: row;
                    margin-right: $grid-gutter-width * -.5;
                    margin-left: $grid-gutter-width * -.5;

                    > * {
                        flex: 0 0 auto;
                        padding-right: $grid-gutter-width * .5;
                        padding-left: $grid-gutter-width * .5;
                        width: 25%;
                    }
                }

                &__panel {
                    display: flex;
                    flex-direction: column;
                    transition: opacity .3s linear, transform .3s ease-in-out;

                    &.main-navigation__panel--hide {
                        opacity: 0;
                        transform: translateX(-#{$grid-gutter-width});
                        pointer-events: none;
                    }

                    // &:first-child {
                    //     + .main-navigation__panel {
                    //         + .main-navigation__panel {
                    //             + .main-navigation__panel {
                    //                 .main-navigation__button {
                    //                     pointer-events: none;

                    //                     span {
                    //                         &[aria-hidden='true'] {
                    //                             display: none;
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    flex-shrink: 0;
                    padding-bottom: 32px;
                    height: 100px;
                    border-bottom: 1px solid $color__gray;

                    &.main-navigation__header--home {
                        > a {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            @include hover-focus-visible {
                                &::before {
                                    filter: $filter__font;
                                }
                            }

                            &::before {
                                content: "";
                                flex-shrink: 0;
                                display: inline-block;
                                margin-right: 8px;
                                width: 24px;
                                height: 24px;
                                border: 0;
                                background: {
                                    color: transparent;
                                    image: url(/bundles/hitcomfrontend/img/material-symbols/400/outlined/home.svg);
                                    position: 0 0;
                                    repeat: no-repeat;
                                    size: 100% 100%;
                                }
                                filter: $filter__primary;
                                transition: filter .15s linear;
                            }
                        }
                    }

                    &__list {
                        &:not(.main-navigation__list--header) {
                            flex: 1;
                        }
                    }

                    > * {
                        // display: block;
                        line-height: 1;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }
    }
 }


/**
 * Desktop Navigation
 */

@if $main-navigation__mode == menu {
    @media (min-width: #{$off-canvas__breakpoint}) {
        html.js {
            .main-navigationx {
                position: fixed;
                top: 0;
                right: 0;

                > .main-navigation__nav {
                    > ul {
                        display: flex;
                        flex-direction: row;

                        > li {
                            > button {
                                appearance: none;
                                padding: 0;
                                color: #000;
                                background-color: transparent;
                                border: 0;
                                border-radius: 0;

                                > a {
                                    transition: line-height .3s ease-in-out, color .2s linear;
                                }
                            }

                            > .main-navigation__sub {
                                position: absolute;
                                top: 100%;
                                right: 0;
                                background-color: #fff;

                                > nav {
                                    display: flex;
                                    flex-direction: row;
                                    transition: height .3s ease-in-out, opacity .3s linear, transform .3s ease-in-out;

                                    &.main-navigation__nav--absolute {
                                        position: absolute;
                                        top: 0; left: 0;
                                        width: 100%;

                                        &.main-navigation__nav--show {
                                            opacity: 0;
                                            transition: height .3s ease-in-out;

                                            &.main-navigation__nav--prev {
                                                transform: translateX(-33.333333%);
                                            }

                                            &.main-navigation__nav--next {
                                                transform: translateX(33.333333%);
                                            }
                                        }
                                    }

                                    &.main-navigation__nav--hide {
                                        opacity: 0;
                                        pointer-events: none;

                                        &.main-navigation__nav--prev {
                                            transform: translateX(33.333333%);
                                        }

                                        &.main-navigation__nav--next {
                                            transform: translateX(-33.333333%);
                                        }
                                    }

                                    > ul {
                                        flex: 0 0 auto;
                                        width: 33.333333%;
                                        overflow-x: hidden;
                                        overflow-y: auto;

                                        @include moz-scrollbar($thumb-color: $color__gray);
                                        @include webkit-scrollbar($scrollbar-size: 12px, $thumb-size: 4px, $thumb-color: $color__gray);

                                        > li {
                                            > a {
                                                //line-height: 100px;
                                            }

                                            > button {
                                                //line-height: 100px;
                                            }
                                        }
                                    }
                                }

                                > button {
                                    position: absolute;
                                    top: 0; right: 0;
                                    width: $main-navigation__link--height;
                                    height: $main-navigation__link--height;
                                }

                                .main-navigation {
                                    &__list {
                                        &.main-navigation__list--header {
                                            //flex-direction: column;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/**
 * Project styles
 */

//.main-navigation {
//    &__button {
//        &:focus-visible {
//            outline: 2px solid $color__primary-0;
//            outline-offset: -2px;
//        }
//    }
//
//    &__link {
//        &:focus-visible {
//            outline: 2px solid $color__primary-0;
//            outline-offset: -2px;
//        }
//    }
//}

//html.no-js {
//    .main-navigation {
//        @include moz-scrollbar;
//        @include webkit-scrollbar;
//    }
//}

//html.js {
//    .main-navigation {
//        > .main-navigation__nav {
//            > ul {
//                &.main-navigation__list--header {
//                    + ul {
//                        li {
//                            &:first-child {
//                                a,
//                                button {
//                                    border-top: 0;
//                                }
//                            }
//
//                            a,
//                            button {
//                                padding-left: 16px;
//                                font-size: .9em;
//                                font-weight: $font__weight--normal;
//                            }
//                        }
//                    }
//                }
//
//                > li {
//                    > a,
//                    > button {
//
//                    }
//
//                    > button {
//                        > a {
//                            > span {
//                                &[aria-hidden] {
//                                    position: relative;
//                                    font-size: 0;
//                                    background: {
//                                        image: url(/assets/Themes/website/images/arrow.svg);
//                                        position: (($main-navigation__link--height - 28px) * .5) center;
//                                        repeat: no-repeat;
//                                        size: 28px 28px;
//                                    }
//                                    transition: background-position .2s ease-in-out;
//                                }
//                            }
//                        }
//
//                        &:hover,
//                        &:focus {
//                            > a {
//                                color: $color__primary-0;
//
//                                > span {
//                                    &[aria-hidden] {
//                                        background-position: ((($main-navigation__link--height - 28px) * .5) + 4px) center;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//        }
//
//        &__list {
//            &:not(.main-navigation__list--header) {
//                @include moz-scrollbar;
//                @include webkit-scrollbar;
//            }
//        }
//
//        &__item {
//            &:first-child {
//                > a,
//                > button {
//                    border-top: 1px solid $color__gray-1;
//                }
//            }
//
//            > a,
//            > button {
//                border-bottom: 1px solid $color__gray-1;
//            }
//        }
//
//        &__button {
//            &.main-navigation__button--prev {
//                > span {
//                    &[aria-hidden] {
//                        margin-right: 8px;
//                        width: 32px;
//                        background-image: url(/assets/Themes/website/images/arrow-dark.svg);
//                        background-position: 0 center;
//                        transform: rotate(180deg);
//                    }
//                }
//
//                &:hover,
//                &:focus {
//                    > span {
//                        &[aria-hidden] {
//                            background-position: 4px center;
//                        }
//                    }
//                }
//            }
//
//            > span {
//                &[aria-hidden] {
//                    position: relative;
//                    font-size: 0;
//                    background: {
//                        image: url(/assets/Themes/website/images/arrow.svg);
//                        position: (($main-navigation__link--height - 28px) * .5) center;
//                        repeat: no-repeat;
//                        size: 28px 28px;
//                    }
//                    transition: background-position .2s ease-in-out;
//                }
//            }
//
//            &:hover,
//            &:focus {
//                color: $color__primary-0;
//
//                > span {
//                    &[aria-hidden] {
//                        background-position: ((($main-navigation__link--height - 28px) * .5) + 4px) center;
//                    }
//                }
//            }
//        }
//
//        &__link {
//            &:hover,
//            &:focus {
//                color: $color__primary-0;
//            }
//        }
//    }
//}

//@if not $off-canvas__desktop {
//    @media (min-width: #{$off-canvas__breakpoint}) {
//        html.js {
//            .main-navigation {
//                right: $grid-gutter-width * .5;
//
//                @each $breakpoint, $container-padding-size in $container-padding-sizes {
//                    @include media-breakpoint-up(#{$breakpoint}) {
//                        right: $container-padding-size;
//                    }
//                }
//
//                @media (min-width: #{map-get-last-value($container-max-widths)}) {
//                    right: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
//                    right: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
//                }
//
//                > .main-navigation__nav {
//                    > ul {
//                        > li {
//                            &:not(:first-child) {
//                                margin-left: 32px;
//                            }
//
//                            > button {
//                                > a {
//                                    line-height: $header__height--mobile;
//
//                                    @include media-breakpoint-up(lg) {
//                                        line-height: $header__height--unpinned;
//                                    }
//
//                                    @include media-breakpoint-up(xxl) {
//                                        line-height: $header__height;
//                                    }
//                                }
//                            }
//
//                            > .main-navigation__sub {
//                                width: calc(100vw - #{$grid-gutter-width});
//                                width: calc((var(--vw, 1vw) * 100) - #{$grid-gutter-width});
//                                background-color: transparent;
//
//                                @each $breakpoint, $container-padding-size in $container-padding-sizes {
//                                    @include media-breakpoint-up(#{$breakpoint}) {
//                                        width: calc(100vw - #{$container-padding-size * 2});
//                                        width: calc((var(--vw, 1vw) * 100) - #{$container-padding-size * 2});
//                                    }
//                                }
//
//                                @media (min-width: #{map-get-last-value($container-max-widths)}) {
//                                    width: map-get-last-value($container-max-widths) - (map-get-last-value($container-padding-sizes) * 2);
//                                }
//
//                                &::before {
//                                    content: "";
//                                    position: absolute;
//                                    z-index: -1;
//                                    top: 0; left: 50%;
//                                    width: calc(100vw + 20px);
//                                    height: 100%;
//                                    background-color: $color__gray-2;
//                                    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.12);
//                                    transform: translateX(-50%);
//                                }
//
//                                > nav {
//                                    padding-top: 64px;
//                                    height: calc(100vh - #{$header__height--mobile});
//                                    height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile});
//
//                                    @include media-breakpoint-up(lg) {
//                                        height: calc(100vh - #{$header__height--unpinned});
//                                        height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned});
//                                    }
//
//                                    @include media-breakpoint-up(xxl) {
//                                        height: calc(100vh - #{$header__height});
//                                        height: calc((var(--vh, 1vh) * 100) - #{$header__height});
//                                    }
//
//                                    > ul {
//                                        @include moz-scrollbar;
//                                        @include webkit-scrollbar;
//
//                                        &.main-navigation__list--header {
//                                            > li {
//                                                > a {
//                                                    font-size: 1.1em;
//                                                    text-decoration: underline;
//                                                    text-decoration-thickness: .08em;
//                                                    color: $color__primary-0;
//                                                    border: 0;
//
//                                                    &:hover,
//                                                    &:focus {
//                                                        text-decoration: none;
//                                                    }
//                                                }
//
//                                                > button {
//                                                    border: 0;
//                                                }
//                                            }
//                                        }
//
//                                        &:not(.main-navigation__list--header) {
//                                            > li {
//                                                > a,
//                                                > button {
//                                                    padding-left: 16px;
//                                                    font-size: .9em;
//                                                    font-weight: $font__weight--bold;
//                                                }
//                                            }
//                                        }
//                                    }
//                                }
//
//                                > button {
//                                    top: 64px;
//                                    transition: color .2s linear;
//
//                                    &:hover,
//                                    &:focus {
//                                        color: $color__primary-0;
//                                    }
//
//                                    &::before,
//                                    &::after {
//                                        content: "";
//                                        position: absolute;
//                                        top: 50%; left: 50%;
//                                        width: 28px;
//                                        height: 2px;
//                                        background-color: currentColor;
//                                    }
//
//                                    &::before {
//                                        transform: translate(-50%, -50%) rotate(45deg);
//                                    }
//
//                                    &::after {
//                                        transform: translate(-50%, -50%) rotate(-45deg);
//                                    }
//
//                                    span {
//                                        display: none;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//
//            &.headroom--unpinned {
//                .main-navigation {
//                    > .main-navigation__nav {
//                        > ul {
//                            > li {
//                                > button {
//                                    > a {
//                                        @include media-breakpoint-up(xxl) {
//                                            line-height: $header__height--unpinned;
//                                        }
//                                    }
//                                }
//
//                                > .main-navigation__sub {
//                                    > nav {
//                                        @include media-breakpoint-up(xxl) {
//                                            height: calc(100vh - #{$header__height--unpinned});
//                                            height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned});
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//        }
//    }
//}
